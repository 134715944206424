<template>
  <div v-if="datalist.length>0">
      <div class="title">
      <p class="title1">{{ record.label }}</p>
      <p class="title2">{{ record.remarks }}</p>
    </div>
    <div class="bignews">
    <div class="news">
      <div class="left">
        <div class="time" v-for="item in datalist" :key="item.id">
          <p>{{ item.releaseTime | timefil }}</p>
          <p>{{ item.releaseTime | datefil }}</p>
          <div class="circular"></div>
        </div>
      </div>
      <div class="right">
        <div class="right-center" @click='routejump(item)' v-for="item in datalist" :key="item.id">
          <ul>
            <li><img :src="item.coverSquare" /></li>
            <li class="dsbe">
              <p>
                {{ item.title }}
              </p>
              <div>
                {{ item.subtitle }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="foot">
      <button class="foot_but" @click='load'  v-if='flag'>加载更多</button>
    </div>
  </div>
  </div>
</template>

<script>
import { newsList } from '../api/index'
export default {
  props: ['record'],
  data () {
    return {
      params: {
        pageNum: 1,
        pageSize:4,
        type: ""
      },
      datalist: [],
      total: 0,
      flag: false,
    }
  },
  created () {
    if (this.record != undefined) {
      this.params.type = this.record.value;
      this.list()
    }
  },
  methods: {
    list () {
      let p = this.params
      newsList(p).then((res) => {
        this.total = res.data.total
        this.datalist = res.data.list
        if (res.data.total > this.params.pageSize) {
          this.flag = true;
        }else{
              this.flag = false;
        }
      })
    },
    // 路由跳转
     routejump(val){
    //    console.log(val)
       this.$router.push({path:'/details',query:{id:val.id,type:this.params.type}})
    },
    //加载更多
    load(){
      this.params.pageSize+=5
    //   console.log(this.params.pageSize)
      this.list()
    }
  },
  filters: {
    // 获取天数
    timefil (val) {
      let time = new Date(val).getDate()
      // console.log(time)
      if (time < 10) {
        time = '0' + time
      }
      return time
    },
    // 获取年月
    datefil (val) {
      let year = new Date(val).getFullYear()
      let month = new Date(val).getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      return `${year}.${month}`
    }
  },
  watch: {
    'record': function (newval) {
      this.params.type = newval.value;
      this.list()
    }

  }
}
</script>

<style scoped lang="scss">
.title {
  margin-top: 100px;
  margin-bottom: 80px;
  .title1 {
    text-align: center;
    font-size: 40px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 9px;
  }
  .title2 {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }
}
.bignews {
  margin: auto;
  width: 1200px;
  margin-bottom: 40px;

  .news {
    width: 1098px;
    // border: 1px solid red;
    box-sizing: border-box;
    display: flex;
    .left {
      margin-right: 50px;
      .time {
        position: relative;
        height: 167px;
        width: 135px;
        border-right: 1px solid #222222;
        p:nth-child(1) {
          font-size: 40px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          text-align: LEFT;
          color: #ffffff;
          letter-spacing: 0px;
        }
        p:nth-child(2) {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
        }
        .circular {
          position: absolute;
          width: 11px;
          height: 11px;
          background: #424242;
          border-radius: 50%;
          right: -5px;
          top: 10px;
        }
      }
    }
    .right {
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid blue;
      .right-center {
        margin-bottom: 40px;
        ul {
          display: flex;
          li:nth-child(1) {
            img {
              border-radius: 6px;
              width: 140px;
              height: 127px;
            }
          }
          .dsbe {
            box-sizing: border-box;
            margin-left: 30px;
            width:733px;
            p {
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #ffffff;
              margin-bottom: 15px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            div {
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #999999;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
      }
      .right-center:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .foot {
    padding-top: 41px;
    width: 1200px;
    margin: auto;
    //   padding-bottom: 80px;
    .foot_but {
      border: none;
      display: block;
      margin: auto;
      width: 100%;
      height: 60px;
      background: #202020;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: #eeeeee;
    }
  }
}
</style>
