
<template>
  <div class="index">
    <div class="big">
      <div class="rotation">
        <hi-carousel
          :interval="2000"
          type="card"
          height="339px"
          :autoplay="false"
          :initial-index="bannerindex"
        >
          <hi-carousel-item v-for="(item, index) in data" :key="item.id">
            <div class="image" @click="routejump(item, index)" >
              <img :src="item.coverBanner || item.coverSquare" alt="" />
            </div>
          </hi-carousel-item>
        </hi-carousel>
      </div>
      <div v-for=" item in dictInfo" :key="item.id">
      <MusicianGrid :record='item' v-if='item.busiType==1' />
      <Abea :record='item' v-if='item.busiType==2' />
      <NewList :record='item' v-if='item.busiType==3'  />
      </div>
    </div>
  </div>
</template>

<script>
import { homePage, dictInfo } from '../api/index';
import Abea from '../components/Abea.vue';
import MusicianGrid from '../components/MusicianGrid.vue';
import NewList from '../components/NewList.vue';
import HiCarousel from "@/components/HiCarousel/src/main"
import HiCarouselItem from "@/components/HiCarousel/src/item"
export default {
  created () {
    // ElCarouselItem.methods.
    dictInfo({ codes: ['11001'] }).then(res => {
             this.dictInfo=res.data;
    })
    this.fnhomePage()
  },
  components: {
    Abea, MusicianGrid, NewList, HiCarousel, HiCarouselItem
  },
  data () {
    return {
      // 轮播图
      params1: {
        pageNum: 1,
        pageSize: 10,
      },
      data: '',
      // 资讯类型
      dictInfo:[],
    // 音乐人专访
      busiType1:1,
    // 音乐学院
      busiType2:1,
    // 新闻公告
      busiType3:1,
    };
  },
computed:{
    bannerindex(){
        return this.$store.state.bannerindex
    }
},
  methods: {
    // 获取轮播图信息
    fnhomePage () {
      homePage(this.params1).then((res => {
        this.data = res.data;
      }))
    },
   routejump(val,index) {
       this.$store.state.bannerindex=index
       this.$router.push({path:'/details',query:{id:val.id}})
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../styles/initialization.scss");
.index {
  width: 100%;
  min-height:100vh;
  background-color: #000000;
      padding-bottom: 80px;
  .big {
    overflow: hidden;
    margin: auto;
    width: 1422px;
    // border: 1px solid red;

    .rotation {
      width: 1200px;
      margin: 100px auto auto;

      ::v-deep .el-carousel__arrow {
        display: none;
      }
      ::v-deep .el-carousel__item {
        border-radius: 6px;
      }
      .image {
        width: 855px;
        height: 339px;
        border-radius: 6px;
        img {
          display: inline-block;
          width: 855px;
          height: 339px;
          border-radius: 6px;
        }
      }
    }
  }
}
</style>
