<template>
  <div  v-if="datalist.length>0" >
    <div class="title">
      <p class="title1">{{ record.label }}</p>
      <p class="title2">{{ record.remarks }}</p>
    </div>
    <div class="school">
      <div class="leftbtn btn" v-if="leftbutbol" @click="leftbut"></div>
      <div class="leftbtn btn bgleft" v-else></div>
      <div class="center">
        <div
          class="item"
          @click="routejump(item)"
          v-for="item in datalist"
          :key="item.id"
        >
          <div class="item-image">
            <img :src="item.coverSquare" alt="" srcset="" />
          </div>
          <div class="item-title">
            <p>{{ item.title }}</p>
            <p>{{ item.subTitle }}</p>
          </div>
        </div>
      </div>
      <div @click="rightbut" v-if="rightbutbol" class="rightbtn btn"></div>
      <div class="rightbtn btn bgright" v-else></div>
    </div>
  </div>
</template>

<script>
import { newsList } from '../api/index'
export default {
  props: ['record'],
  data () {
    return {
      parmas: {
        pageNum: 1,
        pageSize: 3,
        type: ""
      },
      datalist: [],
      // 总条数
      total: 0,
      leftbutbol: false,
      rightbutbol: false,

    }
  },
  created () {
    if (this.record != undefined) {
      this.parmas.type = this.record.value
      this.list()
    }
  },
  methods: {
    list () {
      let p = this.parmas
      newsList(p).then((res) => {
        this.datalist = res.data.list
        this.total = res.data.total
        if (res.data.total > (this.parmas.pageSize * this.parmas.pageNum)) {
          this.rightbutbol = true;
        } else {
          this.rightbutbol = false;
        }
        if (res.data.pageNum > 1) {
          this.leftbutbol = true;
        } else {
          this.leftbutbol = false;
        }
      })
    },

    //路由跳转
    routejump (val) {
      this.$router.push({ path: '/details', query: { id: val.id , type:this.parmas.type} })
    },
    //右切换
    rightbut () {
      let num = this.parmas.pageNum * this.parmas.pageSize
      if (num < this.total) {
        this.parmas.pageNum += 1
        this.list()
      }
    },
    leftbut () {

      if (this.parmas.pageNum > 1) {
        this.parmas.pageNum -= 1
        this.list()
      }
    }
  },
  watch: {
    'record': function (newval) {
      this.parmas.type = newval.value
      this.list()
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  margin-top: 100px;
  margin-bottom: 80px;
  .title1 {
    text-align: center;
    font-size: 40px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 9px;
  }
  .title2 {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }
}
.school {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .center {
    // border: 1px solid red;
    width: 1200px;
    height: 340px;
    display: flex;
    // justify-content: space-between;
    .item {
      width: 360px;
      height: 100%;
      margin-left: 56px;
      box-sizing: border-box;
      border-radius: 6px;
      overflow: hidden;
      .item-image {
        box-sizing: border-box;
        width: 100%;
        height: 240px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-title {
        background: #121212;
        height: 100px;
        box-sizing: border-box;
        padding-left: 16px;

        p:nth-child(1) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-top: 20px;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #ffffff;
        }
        p:nth-child(2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-top: 16px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #999999;
        }
      }
    }
    .item:nth-child(1) {
      margin-left: 0px;
    }
  }

  .btn {
    display: none;
    width: 40px;
    height: 40px;
    // border: 1px solid red;
  }
  .leftbtn {
    margin-right: 70px;
    background: url("../assets/Vectorleft.png");
    background-size: 100% 100%;
  }
  .rightbtn {
    margin-left: 70px;
    background: url("../assets/Vectorright.png");
    background-size: 100% 100%;
  }
  .bgleft {
    background: url("../assets/Vectorleft1.png");
    background-size: 100% 100%;
  }
  .bgright {
    background: url("../assets/Vectorright1.png");
    background-size: 100% 100%;
  }
}
.school:hover .btn {
  display: block;
}
</style>
